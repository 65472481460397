const default_admin_permissions = {
  orders: true,
  live_support: true,
  tickets: true,
  settings: true,
  tuning_services: true,
  api_manager: true,
  miscellaneous: true,
  vehicle_statistics: {
    enabled: true,
    variants: {
      enabled: true,
      add: true,
      edit: true,
      delete: true,
      show_variants_table: true,
      edit_details: true,
      show_data_on_edit_details: true
    },
    generations: {
      enabled: true,
      add: true,
      edit: true,
      delete: true
    },
    manufacturers: {
      enabled: true,
      add: true,
      edit: true,
      delete: true
    },
    models: {
      enabled: true,
      add: true,
      edit: true,
      delete: true
    },
    types: {
      enabled: true,
      add: true,
      edit: true,
      delete: true
    }
  },
  tnc: true,
  dealers_manager: {
    enabled: true,
    send_email: true,
    dealers_list: true,
    edit_dealer: true,
    delete_dealer: true,
    change_role: true,
    login_to_dealer_account: true,
    change_dealer_password: true,
    dealers_invoices: true,
    dealers_balance: true,
    delete_dealer_invoice: true,
    download_dealer_invoice: true,
    export_users: true,
  },
  shop_manager: {
    enabled: true,
    add_category: true,
    edit_category: true,
    delete_category: true,
    add_coupon: true,
    edit_coupon: true,
    delete_coupon: true,
    add_shipping_method: true,
    edit_shipping_method: true,
    delete_shipping_method: true,
    add_product: true,
    edit_product: true,
    delete_product: true,
    mark_as_paid_order: false,
    process_order: false,
    hold_order: true,
    cancel_order: true,
    complete_order: true,
    ship_order: true,
    shop_settings: true,
  },
};

let mutations = {
  left_menu(state, option) {
    if (option === "open") {
      state.left_open = true;
    } else if (option === "close") {
      state.left_open = false;
    } else if (option === "toggle") {
      state.left_open = !state.left_open;
    }
    if (state.left_open) {
      document.getElementsByTagName("body")[0].classList.remove("left-hidden");
    } else {
      document.getElementsByTagName("body")[0].classList.add("left-hidden");
    }
  },
  
  route_change(state, loader) {
    if (loader === "start") {
      state.preloader = true;
    } else if (loader === "end") {
      state.preloader = false;
    }
  },
  
  login_success(state, user) {
    localStorage.removeItem("forgot_user");
    state.loggedIn = true;
    if (user.role_id === 1) {
      user.permissions = default_admin_permissions;
    }
    state.user = user;
  },
  
  setGooglePlaceID(state, googlePlaceID) {
    state.user = {
      ...state.user,
      google_place_id: googlePlaceID
    }
  },
  
  login_failure(state) {
    state.loggedIn = false;
    state.user = null;
  },
  
  update_user_data(state, user) {
    state.loggedIn = true;
    if (user.role_id === 1) {
      user.permissions = default_admin_permissions;
    }
    state.user = user;
  },
  
  update_site_settings(state, settings) {
    state.settings = settings;
  },
  
  update_shop_settings(state, shop_settings) {
    state.shop_settings = shop_settings;
  },
  
  update_pending_shop_orders(state, pending_shop_orders) {
    state.pending_shop_orders = pending_shop_orders;
  },
  
  update_my_incomplete_shop_orders(state, count) {
    state.my_incomplete_shop_orders = count;
  },
  
  update_shopping_cart(state, shopping_cart) {
    state.shopping_cart = shopping_cart;
  },
  
  update_checkout_data(state, { applied_coupon, shipping_method }) {
    state.checkout = { applied_coupon, shipping_method };
  },
  
  update_theme_settings(state, data) {
    state.theme = data;
  },
  
  logout(state) {
    state.loggedIn = false;
    state.user = null;
  },
  
  refresh_token(state, access_token) {
    state.loggedIn = true;
    if (state.user.role_id === 1) {
      state.user.permissions = default_admin_permissions;
    }
    state.user = { ...state.user, access_token: access_token };
  },
  
  pending_orders(state, orders_count) {
    state.pending_orders = orders_count;
  },
  
  user_pending_orders(state, orders_count) {
    state.user_pending_orders = orders_count;
  },
  
  warning_orders(state, orders_count) {
    state.warning_orders = orders_count;
  },
  
  agent_pending_orders(state, orders_count) {
    state.agent_pending_orders = orders_count;
  },
  
  agent_warning_orders(state, orders_count) {
    state.agent_warning_orders = orders_count;
  },
  
  pending_message(state, message_count) {
    state.pending_messages = message_count;
  },
  
  unread_notifications(state, notifications_count) {
    state.unread_notifications = notifications_count;
  },
  
  open_tickets(state, tickets_count) {
    state.open_tickets = tickets_count;
  },
  
  route_name(state, name) {
    state.route_name = name;
  },
  
  setHasAccessToVehicleStatisticsApi(state, hasAccessToVehicleStatisticsApi) {
    if (typeof hasAccessToVehicleStatisticsApi === "boolean") {
      state.hasAccessToVehicleStatisticsApi = hasAccessToVehicleStatisticsApi
    }
  }
};

export default mutations;
